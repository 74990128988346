import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import NavigationBar from './components/NavigationBar'

import Home from './pages/Home/Home'
// import About from './pages/About/About'
// import Gallery from './pages/Gallery/Gallery'
// import Videos from './pages/Videos/Videos'
import NotFound from './pages/NotFound/NotFound'


const App = () => (
  <React.Fragment>
    <BrowserRouter>
      <div style={{backgroundColor: '#d4daf7'}}>
      <NavigationBar />
      <Switch>
        <Route exact path='/' component={Home} />
        <Route component={NotFound} />
      </Switch>
      <hr style={{borderTop: '2px solid #999', marginTop: 30 }}/>
      <footer>
        <div className="flex-column d-flex justify-content-center align-items-center" style={{height: 120}}>
          <p><span>Copyright Tina Motley 2020</span><span style={{paddingLeft: 200}}>Moltey Critters</span></p>
        </div>
      </footer>
      </div>
    </BrowserRouter>
  </React.Fragment>
)

export default App
