import React, { Component } from 'react'
//import './Home.css'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
//import { Link } from 'react-router-dom'
import styled from 'styled-components'

import JumboViewer from '../../components/JumboViewer'
import BackgroundImage from '../../components/BackgroundImage'
import PictureCell from '../../components/PictureCell'


import tinaAndDonkeyImage from '../../assets/pets/donkey1-less-exposure1-lower-res1.jpg'
import tina_and_baby_goats1_image from '../../assets/pets/tina-and-baby-goats1.png'
import group_art_pieces1Image from '../../assets/art/group_art_pieces1.jpg'
import beagle1Image from '../../assets/art/beagle1.jpg'
import bird_creature1Image from '../../assets/art/bird_creature1.jpg'
import birds_opus_and_aunt_b1Image from '../../assets/art/birds_opus_and_aunt_b1.jpg'
import dragon5Image from '../../assets/art/dragon5.jpg'
import flamingo2Image from '../../assets/art/flamingo2.jpg'
import otter1Image from '../../assets/art/otter1.jpg'

const imageList = [
  [
    birds_opus_and_aunt_b1Image,
    'birds_opus_and_aunt_b1',
    'desc'
  ], [
    dragon5Image,
    'dragon5',
    'This is a cool dragon!'
  ], [
    bird_creature1Image,
    'bird_creature1',
    'desc'
  ], [
    flamingo2Image,
    'flamingo2',
    <p>This is ceramic flamingo has a height of<br />
    approximately 24 inches.<br />
    The legs are painted, metal rebar<br />
    on a base cut from a log.</p>
  ], [
    beagle1Image,
    'beagle1.jpg',
    <p>This is a nice Beagle, if you like beagles. <br />Why beagles?</p>
  ], [
    otter1Image,
    'otter1',
    'desc'
  ]
]


const Styles = styled.div`
/*
  .videoImage {
  }
  .videosHeader {
    font-size: 60px;
    font-weight: 400;
    color: black;
  }
  .videosText {
    font-size: 20px;
    font-weight: 400;
    color: darkblue;
  }
*/
  .aboutHeader {
    font-size: 40px;
    font-weight: 600;
    color: yellow;
    background-color: blue;
    display: inline;
  }
  .aboutText {
    font-size: 20px;
    font-weight: 600;
    color: #222;
  }
/*
  .galleryHeader {
    font-size: 40px;
    font-weight: 400;
    color: black;
  }
*/
  .middleContainer {
    margin-left: 255px;
    margin-right: 255px;
    padding: 0px;
  }
  .midContainerCol1 {
    min-width: 611px;
  }
  .midContainerCol2 {
    padding-left: 6px;
    padding-right: 0px;
  }
  .gPicCell {
    margin: 0px;
  }

`;

class Home extends Component {
  render(props) {
    return (
      <Styles>
        <JumboViewer image={group_art_pieces1Image}/>
        <Container className="middleContainer">
          <Row>
            <Col className="midContainerCol1">
              <BackgroundImage image={tina_and_baby_goats1_image} className="videoImage">
                {
                /*
                <div className="videosText">
                  <Link className="btn btn-primary btn-lg" to="/videos" onClick={(e) => { const target = e.target; setTimeout(() => target.blur(), 200)}}>
                    Videos
                  </Link>
                </div>
                */
                }
              </BackgroundImage>
              <BackgroundImage image={tinaAndDonkeyImage} className="videoImage">
              </BackgroundImage>
            </Col>
            <Col className="midContainerCol2">
              <div className="aboutText">
                <p>Tina Motley has been an artist most of her life, but she finds the
                most joy transforming a lump of clay into whimsical characters.
                Motley has been making anything from dragons to dogs for more than 20 years.
                Tina is native to NC, and resides on her small farm in Durham with her family
                and menagerie of animals.  She is inspired by her real life critters such as
                llamas, horses, a donkey, goats, chickens, rabbits, dogs, and a cat.
                Her sculptures are created and fired on site at her studio located at her home.
                Each character is individually sculpted from clay, slowly dried, and
                bisque fired in the kiln.  Each piece is then carefully glazed and fired again.
                </p>
                <p>
                Contact artwork@tinamotley.com for inquiries regarding commissions or to visit her studio gallery.
                </p>
              </div>
              <div
                className="flex-row d-flex flex-wrap align-content-around align-items-center"
                style={{marginTop: 30}}
              >
                {
                  imageList.map((img, index) => {
                    return(
                      <PictureCell
                        key={`${index}`}
                        image={img[0]}
                        caption={img[1]}
                        details={img[2]}
                        myThis={this}
                        className="gPicCell"
                      />
                    )
                  })
                }
              </div>
              {
              /*
              <Link className="btn btn-primary btn-lg" to="/About" onClick={(e) => { const target = e.target; setTimeout(() => target.blur(), 200)}}>
                ABOUT
              </Link>
              */
              }
            </Col>
          </Row>
        </Container>
      </Styles>
    )
  }
}

export default Home
