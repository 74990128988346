import React, { Component } from 'react'
import './NotFound.css'

class NotFound extends Component {
  render() {
    return (
      <div className="NotFound">
        <h1>Page not found</h1>
      </div>
    );
  }
}

export default NotFound
