import React from 'react'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

// import Navbar from 'react-bootstrap/Navbar'

import styled from 'styled-components'

const Styles = styled.div`
  .backgroundImage {
    background: url(${props => props.image}) no-repeat /* fixed bottom */;
    background-size: contain;
    color: black;
    height: 450px;
    margin-left: 0px;
    padding-bottom: 70px;

    /* position: relative; */
    /* opacity: 0.8; */
    /* z-index: -2; */
  }

  .innerContainer {
    padding-top: 100px;
    padding-left: 0px;
  }
`;

const BackgroundImage = (props) => (
  <Styles image={props.image} >
    <Jumbotron className="d-flex flex-row justify-content-center align-items-end backgroundImage">
      <Row>
        <Container className="d-flex flex-column align-items-start innerContainer">
          {props.children}
        </Container>
      </Row>
    </Jumbotron>
  </Styles>
);

export default BackgroundImage;
