import React from 'react'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'
import { Link } from 'react-router-dom'
// import Navbar from 'react-bootstrap/Navbar'

import styled from 'styled-components'

const Styles = styled.div`
  .jumbotron {
    background: url(${props => props.image}) no-repeat /* fixed bottom */;
    background-size: cover;
    color: #222;
    height: 848px;
    margin-left: 255px;
    margin-right: 255px;
    margin-top: 2px;
    position: relative;
    padding-top: 12px;
    /* opacity: 0.8; */
    /* z-index: -2; */
  }
  .welcomeTitle {
    margin-top: 0px;
    padding-left: 10px;
  }
  .galleryLink {
    // margin-top: 10px;
    color: #fdab62;
    background: black;
    opacity: 0.88;
    border-color: black;
    border-bottom-left-radius: 1.0rem;
    border-bottom-right-radius: 1.0rem;
    border-top-left-radius: 1.0rem;
    border-top-right-radius: 1.0rem;
  }
  .contactTitle {
    padding-top: 510px;
    padding-left: 10px;
  }

  .contactLink {
    text-align: left;
    display: inline-block;
    padding: .5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
  }
  .emailText {
    padding-left: 20px;
  }
`;

const JumboViewer = (props) => (
  <Styles image={props.image} >
    <Jumbotron fluid className="jumbotron">
      <Container className="welcomeTitle" >
        <Link className="btn btn-primary btn-lg disabled galleryLink" to="#" onClick={(e) => {}} >
          <h1><b>Welcome to<br/>MOTLEY CRITTERS<br/>Tina Motley</b></h1>
        </Link>
      </Container>
      <Container className="contactTitle" >
        <div className="galleryLink contactLink" >
          <h2>contact:<br/><span className="emailText">artwork@tinamotley.com</span></h2>
        </div>
      </Container>
    </Jumbotron>
  </Styles>
);

export default JumboViewer;
