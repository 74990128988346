import React from 'react'

// import Navbar from 'react-bootstrap/Navbar'

import styled from 'styled-components'

const PicCellStyles = styled.div`
  .picCell {
    max-width: 100%;
    max-height: 100%;
  }
  .picCellParent {
    width: 180px;
    height: 180px;
    padding: 14px;
    text-align: center;
  }
`;

const PictureCell = (props) =>
  <PicCellStyles>
    <div
      className="picCellParent"
      onClick={(e) => {
        if (props.picClicked) {
          props.picClicked(e, props.image, props.caption, props.details, props.myThis)
        }
      }}
    >
      <img
        src={props.image}
        className="img-fluid picCell"
        alt={props.caption}
      />
    </div>
  </PicCellStyles>

export default PictureCell;
